.dinner-body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: auto;
  width: auto;
}
.dinner-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(28, 27, 27, 1);
  position: relative;
  height: auto;
  width: 100%;
}
.dinner-header > h1 {
  background: -webkit-linear-gradient(90deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10%;
  font-size: 3rem;
}
.dinner-header > h2 {
  background: -webkit-linear-gradient(-90deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
}
.dinner-header > button {
  background: rgb(80, 77, 77, 0.25);
  font-size: 1.5rem;
  font-family: "Outfit", sans-serif;
  margin: 0 auto;
  margin-top: 2.5%;
  color: white;
  text-align: center;
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.85);
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}
.dinner-header > button:active {
  transform: translateY(5px);
  box-shadow: none;
}
.dinner-meal {
  display: flex;
  background: rgb(80, 77, 77, 0.25);
  flex-direction: column;
  margin: 0 auto;
  height: auto;
  width: 40%;
  border-radius: 10px;
  margin-top: 5%;
  text-align: center;
  box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
}
.dinner-meal > h3 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 2rem;
}
.dinner-meal > p {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 1.5rem;
}
.dinner-meal > h4 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 1.5rem;
}
.dinner-meal > ul {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 1.2rem;
}
.dinner-meal > a {
  color: white;
  margin-bottom: 5%;
}
.dinner-meal > img {
  width: 50%;
  height: auto;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .dinner-header > h1 {
    font-size: 2rem;
    margin-top: 20%;
  }
  .dinner-header > h2 {
    font-size: 1.5rem;
  }
  .dinner-header > button {
    font-size: 1rem;
    margin-top: 5%;
  }
  .dinner-body {
    width: 100%;
  }
  .dinner-meal {
    width: 90%;
  }
  .dinner-meal > h3 {
    font-size: 1.5rem;
  }
  .dinner-meal > p {
    font-size: 1.25rem;
  }
}
