.header {
  position: relative;
  width: 100%;
  background-color: rgba(28, 27, 27, 1);
}
.content {
  background: linear-gradient(65deg, #2c6396, #01bb83, #3d64e2, #3ebd03);
  background-size: 400% 450%;
  box-shadow: inset 0 0 15px black;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  animation: gradient 10s ease infinite;
  max-height: 60em;
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-top: 10em;
}
.fractal-1 {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("https://www.transparenttextures.com/patterns/gplay.png");
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.content > h1 {
  color: white;
  background-color: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-size: 3.5em;
  font-family: "Outfit", sans-serif;
  height: auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.frame {
  position: relative;
  z-index: 3;
  border-radius: 50%;
  width: 150px;
  display: flex;
  top: 0.5em;
  margin: 0 auto;
  height: 150px;
  border: 2px solid #003f2f;
  overflow: hidden;
  box-shadow: 0 0 15px black;
}
.content > h2 {
  color: white;
  font-family: "Outfit", sans-serif;
  background-color: none;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  position: relative;
  font-size: 2em;
  z-index: 3;
  height: auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*Mobile Devices*/
@media only screen and (max-width: 767px) {
  .header {
    margin-bottom: 0;
  }

  .content {
    background: linear-gradient(65deg, #2c6396, #01bb83, #3d64e2, #3ebd03);
    background-size: 400% 450%;
    animation: gradient 10s ease infinite;
    height: 25em;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 0;
    padding-top: 5em;
    width: 100%;
  }
  .content > h1 {
    color: white;
    background-color: none;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 60rem;
    font-size: 2.5rem;
    font-family: "Outfit", sans-serif;
    height: auto;
    top: 0;
  }
  .frame {
    position: relative;
    z-index: 3;
    border-radius: 50%;
    width: 100px;
    display: flex;
    top: 0.5em;
    margin: 0 auto;
    height: 100px;
    border: 2px solid #003f2f;
    overflow: hidden;
  }
  .content > h2 {
    color: white;
    background-color: none;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    position: relative;
    font-size: 1.4rem;
    z-index: 3;
    top: 0;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    .header {
      position: relative;
    }

    .content {
      background-color: #3f0010;
      position: relative;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      top: 0;
      gap: 1em;
      padding-top: 4em;
    }
    .content > h1 {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 60rem;
      font-size: 2rem;
      height: auto;
    }
    .frame {
      width: 100px;
    }
  }
}
