.resume
{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: rgba(28,27,27,1);
}

a.resume-link {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  font-family: 'Outfit', sans-serif;
  font-size: 1em;
  text-decoration: none;
  border-radius: 5px;
}
iframe
{
  padding-top: 10em;
  padding-bottom: 3em;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 60em;
  height: 55em;
}
@media only screen and (max-width: 767px)
{
  .resume
  {
    position: relative;
    width: 100%;
    height: 100vh;
  }
 iframe
 {
  padding-top: 8em;
  padding-bottom: 1em;
  width: 20em;
  height: 25em;
 }
 @media only screen and (max-width: 768px) and (orientation: landscape)
 {

  .resume
{
  position: relative;
  width: 100%;
  height: auto;
}
  iframe
  {
    padding-top: 5em;
    width:35em;
    height: 35em;
  }
 }

}