.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  margin-top: 5%;
}
.intro > h1 {
  font-size: 3.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-size: 3.5em;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.intro > p {
  font-size: 3.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-size: 3.5em;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.mentalHealth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
}
.mentalHealth > h1 {
  font-size: 3em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.mentalHealth > p {
  font-size: 2em;
  color: white !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  margin-top: 15%;
}
.mentalHealth > textarea {
  font-size: 1em;
  color: white;
  background-color: rgb(98, 102, 102);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80rem;
  height: 15rem;
  font-family: "Outfit", sans-serif;
  margin-top: 15%;
}
.mentalHealth > button {
  margin: 0 auto;
  display: flex;
  color: white;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  position: relative;
  width: 10rem;
  margin-top: 5%;
  height: 2rem;
  justify-content: center;
  font-size: 1.25rem;
  font-family: "Outfit", sans-serif;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out;
}
.mentalHealth > button:active {
  transform: translateY(5px);
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.85);
}
.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
  margin-top: 15%;
}
.resources > h1 {
  font-size: 3em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.resources > p {
  font-size: 2em;
  color: white !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  margin-top: 5%;
}
.resource-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
  margin-top: 5%;
}
.resource-links > a {
  display: block;
  text-align: center;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease;
}

.resource-links > a:hover {
  background: #0a0a0a;
  transform: translateY(-5px);
}
.therapyfinder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
  margin-top: 15%;
}
.anonemail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
  margin-top: 15%;
}
.anonemail > h1 {
  font-size: 3em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.anonemail > p {
  font-size: 1.5em;
  color: white !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  margin-top: 5%;
}
.anonemail > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
  position: relative;
  margin-top: 1%;
  margin-bottom: 10%;
}
.anonemail > form > label {
  font-size: 2em;
  color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
  margin-top: 5%;
}
.anonemail > form > button {
  margin: 0 auto;
  display: flex;
  color: white;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  position: relative;
  width: 15rem;
  margin-top: 5%;
  height: 2rem;
  justify-content: center;
  font-size: 1.25rem;
  font-family: "Outfit", sans-serif;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .intro {
    margin-top: 20%;
  }
  .intro > h1 {
    font-size: 3em;
  }
  .intro > p {
    font-size: 2em;
  }
  .mentalHealth > h1 {
    font-size: 2em;
  }
  .mentalHealth > p {
    font-size: 1em;
    display: flex;
    height: auto;
    margin: 0 auto;
    flex-direction: column;
    padding: 0.75em 0.75em;
    color: white;
    background: rgb(80, 77, 77, 0.25);
    margin-bottom: 2rem;
    margin-top: 15%;
    max-width: 15rem;
    text-align: center;
    box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
    position: relative;
  }
  .mentalHealth > textarea {
    font-size: 1em;
    color: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    font-family: "Outfit", sans-serif;
    margin-top: 5%;
    resize: both;
  }
  .mentalHealth > button {
    margin: 0 auto;
    display: flex;
    box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
    position: relative;
    width: 10rem;
    margin-top: 5%;
    height: 2rem;
    justify-content: center;
    font-size: 1.25rem;
    font-family: "Outfit", sans-serif;
    border-radius: 30px;
    transition: transform 0.3s ease-in-out;
  }
  .resources > h1 {
    font-size: 2em;
  }
  .resources > p {
    font-size: 1em;
    display: flex;
    height: auto;
    margin: 0 auto;
    flex-direction: column;
    padding: 0.75em 0.75em;
    color: white;
    background: rgb(80, 77, 77, 0.25);
    margin-bottom: 2rem;
    margin-top: 15%;
    max-width: 15rem;
    text-align: center;
    box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
    position: relative;
  }
  .resource-links > a {
    display: block;
    font-size: 1rem;
    background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
    color: #ffffff;
    font-family: "Outfit", sans-serif;
    text-decoration: none;
    padding: 1rem;
    margin: 1rem auto;
    width: 20rem;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, transform 0.3s ease;
    text-align: center;
  }
  .anonemail > h1 {
    font-size: 1.75em;
  }
  .anonemail > p {
    font-size: 1em;
    display: flex;
    height: auto;
    margin: 0 auto;
    flex-direction: column;
    padding: 0.75em 0.75em;
    color: white;
    background: rgb(80, 77, 77, 0.25);
    margin-bottom: 2rem;
    margin-top: 5%;
    max-width: 15rem;
    text-align: center;
    box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
    position: relative;
  }
}
