

.skill-slider
{
    position: relative;
    height:auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 15em;
}
.tech-logos
{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  grid-gap: 3em;
  grid-row-gap: 3em;
  max-width: 60rem;
  padding: 1em;
  height: auto;
  align-items: center;
  justify-content: center;
}
.tech-logo 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .logo-title {
    color: white;
    margin-top: 15px;
    text-align: center;
    font-family:'Outfit', sans-serif;
    font-size: .85em;
  }
  .skill-slider > h1
  {
    background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: none;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 3.5rem; 
    font-family: 'Outfit', sans-serif;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .skill-slider > h2
  {
    background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: none;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 3.5rem; 
    font-family: 'Outfit', sans-serif;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .personal-logos
  {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  max-width: 60rem;
  grid-row-gap: 3em;
  padding: 1em;
  height: auto;
  align-items: center;
  justify-content: center;
   }
 
  .personal-logo
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
@media only screen and (max-width: 768px)  { 

  .skill-slider
  {
      position: relative;
      height: auto;
      overflow: hidden;
      display: flex;
      max-width: 50rem;
      margin-bottom: 0;
      flex-direction: column;
  }
   .tech-logos 
  {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    grid-gap: 1em; 
    grid-row-gap: 3em;
    padding: 1em;
    height: auto;
    max-width: 60rem;
    align-items: center;
    justify-content: center;
  }
  .tech-logo 
  {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .logo-title {
      color: white;
      margin-top: 15px;
      text-align: center;
      font-family:'Outfit', sans-serif;
      font-size: .85em;
    }
    .skill-slider > h1
    {
      background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-color: none;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      font-size: 2.5rem; 
      font-family: 'Outfit', sans-serif;
      margin-top: 2em;
      margin-bottom: 1em;
    }
    .skill-slider > h2
    {
      background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-color: none;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      font-size: 2.5rem; 
      font-family: 'Outfit', sans-serif;
      margin-top: 2em;
      margin-bottom: 1em;
    }
    .personal-logos
    {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    grid-gap: 1em;
    grid-row-gap: 3em;
    padding: 1em;
    height: auto;
    max-width: 60rem;
    align-items: center;
    justify-content: center;
     }
   
    .personal-logo
    {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      
      .skill-slider
      {
          position: relative;
          height: auto;
          overflow: hidden;
          display: flex;
          max-width: 50rem;
          margin-bottom: 0;
          flex-direction: column;
      }
      .tech-logos
      {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        position: relative;
        margin: 0 auto;
        flex-direction: column;
        grid-gap: 4.5em;
        grid-row-gap: 3em;
        padding: 1em;
        height: auto;
        max-width: 60rem;
        align-items: center;
        justify-content: center;
      }
      .tech-logo 
      {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      
        .personal-logos
        {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        position: relative;
        margin: 0 auto;
        flex-direction: column;
        grid-gap: 1em;
        grid-row-gap: 3em;
        padding: 1em;
        height: auto;
        max-width: 60rem;
        align-items: center;
        justify-content: center;
         }
       
  
      }
  }