.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
}
.home-content {
  gap: 2em;
  padding-top: 2em;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(28, 27, 27, 1);
  position: relative;
  height: auto;
  width: 100%;
}
.home-content > h1 {
  font-size: 3.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-size: 3.5em;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.home-content > h2 {
  font-size: 3.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
}

.home-content > p1 {
  font-family: "Outfit", sans-serif;
  font-size: 1.75em;
  color: white;
  margin: 0 auto;
  display: flex;
  height: auto;
  flex-direction: column;
  padding: 0.75em 0.75em;
  margin-bottom: 2rem;
  max-width: 70rem;
  text-align: center;
  position: relative;
  z-index: 6;
}
.home-content > p2 {
  font-family: "Outfit", sans-serif;
  font-size: 1.75em;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 0.75em 0.75em;

  text-align: center;
  margin: 0 auto;
  max-width: 70rem;
  position: relative;
  z-index: 5;
}

.home > a {
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin: -0.75em auto;
  color: black;
  position: relative;
  padding-top: 0;
}
.projects {
  background-color: rgba(28, 27, 27, 1);
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
}

.socials {
  background: rgb(28, 27, 27);
  background: linear-gradient(
    178deg,
    rgba(28, 27, 27, 1) 45%,
    rgba(0, 0, 0, 1) 75%
  );
  height: auto;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  position: relative;
}
.socials > h1 {
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 2.5rem;
  font-family: "Outfit", sans-serif;
}

.social-links {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.social-links > a {
  width: 100%;
  height: auto;
  color: white;
  margin: 0 auto;
  font-size: 2.5em;
  padding: 10px;
  margin-bottom: 2em;
}
.social-links > a:hover {
  color: #01bb83;
  transform: translateY(15px);
}
@keyframes slide-in {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*Media Queries*/

/*Mobile Devices*/
@media only screen and (max-width: 767px) {
  .home-content h1 {
    font-size: 2rem;
  }

  .home-content h2 {
    font-size: 2rem;
  }
  .home {
    height: auto;
    width: 100%;
  }
  .home-content {
    gap: 3em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(28, 27, 27, 1);
    position: relative;
    height: auto;
    padding-top: 2em;
    width: 100%;
  }
  .home-content > p1 {
    font-size: 1.1em;
    display: flex;
    height: auto;
    margin: 0 auto;
    flex-direction: column;
    padding: 0.75em 0.75em;
    color: white;
    background: rgb(80, 77, 77, 0.25);
    margin-bottom: 2rem;
    max-width: 15rem;
    text-align: center;
    box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
    position: relative;
    z-index: 6;
  }
  .home-content > p2 {
    font-size: 1.1em;
    color: white;
    background: rgb(80, 77, 77, 0.25);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0.75em 0.75em;
    bottom: 2em;
    margin-bottom: 2rem;
    max-width: 15rem;
    text-align: center;
    box-shadow: 0px 0px 8px 2px rgb(0, 0, 0, 0.85);
    position: relative;
    top: 1vh;
    z-index: 5;
  }
  .home > a {
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    margin: -0.75em auto;
    color: black;
    position: relative;
    padding-top: 0;
  }
  .projects {
    background-color: rgba(28, 27, 27, 1);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding-top: 3em;
  }

  .socials {
    background: rgb(28, 27, 27);
    background: linear-gradient(
      170deg,
      rgba(28, 27, 27, 1) 45%,
      rgba(0, 0, 0, 1) 65%
    );
    height: auto;
    width: 100%;
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
  }
  .socials > h1 {
    background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 2.5rem;
  }

  .social-links {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .social-links > a {
    width: 100%;
    height: auto;
    color: white;
    margin: 0 auto;
    font-size: 2.5em;
    padding: 10px;
    margin-bottom: 2em;
  }
  @keyframes slide-in {
    from {
      left: -100%;
    }
    to {
      left: 0;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
