.guessing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  width: auto;
}
.guessing-content {
  gap: 2em;
  padding-top: 2em;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(28, 27, 27, 1);
  position: relative;
  height: auto;
  width: 100%;
}
.guessing-content > h1 {
  font-size: 3.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-size: 3.5em;
  font-family: "Outfit", sans-serif;
  height: auto;
  background-clip: text;
}
.guessing-content > h2 {
  font-size: 1.5em;
  background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: none;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60rem;
  font-family: "Outfit", sans-serif;
  height: auto;
}

.check-guess {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  margin: 0 auto;
  margin-top: 2%;
}
.check-guess > input {
  border: 4px solid #eee;
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  color: black;
  font-family: "Outfit", sans-serif;
  font-size: 5rem;
  padding: 0.2rem;
  max-width: 10rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 5%;
  position: relative;
  margin-bottom: 3rem;
  right: 50%;
}
.check-guess > .btn-check {
  margin: 0 auto;
  display: flex;
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  position: relative;
  width: 10rem;
  height: 2rem;
  justify-content: center;
  font-size: 1.25rem;
  font-family: "Outfit", sans-serif;
  right: 50%;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out;
}
.btn-check:active {
  transform: translateY(5px);
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.85);
}

.check-guess > p {
  margin: 0 auto;
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.5rem;
  color: white;
  max-width: 60rem;
  height: auto;
  align-items: center;
  top: 25%;
}
.check-guess > p2 {
  border: 4px solid #eee;
  background: white;
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  color: black;
  font-family: "Outfit", sans-serif;
  font-size: 5rem;
  padding: 0.2rem;
  width: 10rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 5%;
  position: relative;
  margin-bottom: 3rem;
  left: 50%;
}
.check-guess > .btn-reset {
  margin: 0 auto;
  display: flex;
  box-shadow: 5px 10px 5px 1px rgba(0, 0, 0, 0.85);
  position: relative;
  width: 10rem;
  height: 2rem;
  justify-content: center;
  font-size: 1.25rem;
  font-family: "Outfit", sans-serif;
  left: 150%;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out;
}
.btn-reset:active {
  transform: translateY(5px);
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.85);
}
.scores {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  padding-left: 2rem;
}
.scores > .current-score {
  color: white;
  right: 50%;
  font-family: "Outfit", sans-serif;
  margin: 0 auto;
  width: 10rem;
  font-size: 1.5rem;
  margin-top: 2rem;
}
.scores > .hi-score {
  color: white;
  right: 50%;
  font-family: "Outfit", sans-serif;
  margin: 0 auto;
  width: 10rem;
  font-size: 1.5rem;
  margin-top: 2rem;
}
.leaderboard {
  margin: 2rem auto;
  padding: 1rem;
  width: 20rem;
  background-color: rgba(28, 27, 27, 0.8);
  border-radius: 10px;
  color: white;
  font-family: "Outfit", sans-serif;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.85);
}
.leaderboard > h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #01bb83;
}
.leaderboard > ol {
  list-style-type: none;
  padding: 0;
}
.leaderboard > ol > li {
  font-size: 1.25rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}
.player-name {
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.player-name > input {
  border: 2px solid #eee;
  padding: 0.5rem;
  font-family: "Outfit", sans-serif;
  font-size: 1.25rem;
  border-radius: 10px;
  width: 15rem;
  text-align: center;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 767px) {
  .guessing-content > h1 {
    font-size: 2rem;
  }
  .guessing-content > h2 {
    font-size: 1rem;
  }
  .check-guess > input {
    right: 0;
    width: 4rem;
    font-size: 2rem;
  }
  .check-guess > .btn-check {
    width: 6.5rem;
    right: 0;
    color: black;
  }
  .check-guess > p {
    font-size: 1.2rem;
    left: 2%;
    max-width: 25rem;
  }
  .check-guess > p2 {
    left: 0;
    width: 4rem;
    font-size: 2rem;
  }
  .check-guess > .btn-reset {
    left: 100%;
    width: 6.5rem;
    justify-content: center;
    color: black;
  }
}
