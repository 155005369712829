.App {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  height: auto;
}
body {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
}
html {
  width: auto;
  background-color: rgba(28, 27, 27, 1);
  padding: 0;
  margin: 0;
  height: auto;
}
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
  box-shadow: inset 0 0 10px black;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px black;
  background: linear-gradient(95deg, #2c6396, #01bb83, #3d64e2, #3ebd03);
  border-radius: 10px;
}
