.footer {
    background-color: black;
    padding: 20px;
    position: relative;
  }
  
  .copy {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .copy > h1 {
    font-size: 2em;
    background: -webkit-linear-gradient(45deg, #2c6396, #01bb83);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     margin-bottom: 1em;
     font-family: 'Outfit', sans-serif;
  }
  
  .copy > p {
    font-size: 1em;
    margin-top: 10px;
    color: white;
    font-family: 'Outfit', sans-serif;
  }
