.navbar {
  z-index: 998;
  width: 100%;
  position: fixed;
  height: 80px;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.25s ease;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.menu-bars {
  display: block;
  margin-left: auto;
  padding-right: 0.95em;
  font-size: 2rem;
  background: none;
}
.nav-title > h3 {
  font-size: 2.5rem;
  padding-right: 0.5em;
  padding-bottom: 0.45em;
  border-bottom: 3px solid white;
  margin: 0 auto;
  color: white;
  display: flex;
  font-family: "Outfit", sans-serif;
  justify-content: center;
}
.navbar.scrolled {
  background: rgb(53, 52, 52);
}
.nav-menu {
  background: rgba(28, 27, 27, 1);
  background-size: 300% 300%;
  animation: gradient 10s ease infinite;
  width: 25rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  position: fixed;
  right: -100%;
  transition: 500ms ease-out;
  margin: 0;
  border-left: 2px solid white;
  border-top: 2px solid white;
}
.nav-menu.active {
  position: fixed;
  box-shadow: 0 0 0 9999px rgb(0, 0, 0, 0.75);
  right: 0;
  transition: 50ms ease-out;
}
.nav-text {
  display: flex;
  font-family: "Outfit", sans-serif;
  width: 26rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75em 1.75em 1.5em 0;
  list-style: none;
  height: 1rem;
  float: none;
  margin: 0;
  border: none;
}
.nav-text > a {
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2rem;
}
.navbar p {
  padding-top: 0;
  padding-left: 1rem;
  padding-bottom: 0.75rem;
  margin: 0;
  text-decoration-style: none;
  color: white;
  font-family: "Lobster", cursive;
  font-size: 1.5rem;
}
span {
  margin-left: 16px;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*Media Queries*/

/*Mobile Devices*/
@media only screen and (max-width: 767px) {
  .navbar {
    height: 8vh;
  }
  .menu-bars {
    display: block;
    margin-left: auto;
    padding-right: 0.95em;
    font-size: 1.5rem;
    background: none;
  }
  .nav-title > h3 {
    font-size: 2rem;
    padding-right: 1em;
    padding-bottom: 0.45em;
    border-bottom: 3px solid white;
    margin: 0 auto;
    color: white;
    display: flex;
    font-family: serif;
    justify-content: center;
  }
  .navbar.scrolled {
    background: rgb(53, 52, 52);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.85);
  }

  .nav-menu {
    background-color: rgba(28, 27, 27, 1);
    width: 13rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    top: 0;
    position: fixed;
    right: -100%;
    transition: 500ms ease-out;
    margin: 0;
    border-left: 2px solid white;
    border-top: 3px solid white;
  }

  .nav-text {
    display: flex;
    width: 14rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.75em 1.75em 1.5em 0;
    list-style: none;
    height: 1rem;
    float: none;
    margin: 0;
    border: none;
  }
  .nav-text > a {
    text-decoration: none;
    color: white;
    font-size: 1.1em;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1rem;
  }
  .navbar p {
    padding-top: 0;
    padding-left: 1rem;
    padding-bottom: 0.75rem;
    margin: 0;
    text-decoration-style: none;
    color: white;
    font-family: "Lobster", cursive;
    font-size: 1.5rem;
  }
  span {
    margin-left: 16px;
  }

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    .navbar {
      height: 15vh;
    }
  }
}
